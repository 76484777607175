<script lang="ts" setup>
	import useSizes from "~/composables/useSizes";

	import { Sizes } from "~/utils/enums";

	const props = defineProps<{
		size: Sizes;
		color: "white" | "black";
	}>();

	const { size, color } = toRefs(props);

	const { isSizeSmall, isSizeMedium, isSizeLarge, isSizeExtraLarge } = useSizes(size);
</script>

<template>
	<div class="spinner" :class="[color, size]">
		<svg v-if="isSizeExtraLarge" width="160px" height="160px" viewBox="0 0 160 160" xmlns="http://www.w3.org/2000/svg">
			<circle fill="none" stroke-width="2" strokeLinecap="round" cx="80" cy="80" r="76" />
		</svg>
		<svg v-if="isSizeLarge" width="48px" height="48px" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
			<circle fill="none" stroke-width="2" strokeLinecap="round" cx="24" cy="24" r="22" />
		</svg>
		<svg v-if="isSizeMedium" width="28px" height="28px" viewBox="0 0 28 28" xmlns="http://www.w3.org/2000/svg">
			<circle fill="none" stroke-width="2" strokeLinecap="round" cx="14" cy="14" r="12" />
		</svg>
		<svg v-if="isSizeSmall" width="20px" height="20px" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
			<circle fill="none" stroke-width="2" strokeLinecap="round" cx="10" cy="10" r="8" />
		</svg>
	</div>
</template>

<style scoped lang="scss">
	.spinner {
		display: flex;
		justify-content: center;
		align-items: center;
		width: fit-content;
		$offset: 80;
		$duration: 1.2s;

		@keyframes rotator {
			0% {
				transform: rotate(0deg);
			}
			100% {
				transform: rotate(270deg);
			}
		}

		@keyframes whiteSpinner {
			0% {
				stroke: white;
			}
			100% {
				stroke: white;
			}
		}

		@keyframes blackSpinner {
			0% {
				stroke: black;
			}
			100% {
				stroke: black;
			}
		}

		@keyframes dash {
			0% {
				stroke-dashoffset: $offset;
			}
			50% {
				stroke-dashoffset: math.div($offset, 4);
				transform: rotate(135deg);
			}
			100% {
				stroke-dashoffset: $offset;
				transform: rotate(450deg);
			}
		}

		svg {
			animation: rotator $duration linear infinite;
			circle {
				stroke-dasharray: $offset;
				stroke-dashoffset: 0;
				transform-origin: center;
				animation: dash $duration ease-in-out infinite, whiteSpinner ($duration * 2) ease-in-out infinite;
			}
		}

		&.medium {
			stroke-dasharray: $offset * 2;
		}

		&.large {
			stroke-dasharray: $offset * 2;
		}

		&.white {
			circle {
				animation: dash $duration ease-in-out infinite, whiteSpinner ($duration * 2) ease-in-out infinite;
			}
		}

		&.black {
			circle {
				animation: dash $duration ease-in-out infinite, blackSpinner ($duration * 2) ease-in-out infinite;
			}
		}

		@keyframes dashExtraLarge {
			0% {
				stroke-dashoffset: 0;
			}
			50% {
				stroke-dashoffset: math.div(180, 2);
				transform: rotate(135deg);
			}
			100% {
				stroke-dashoffset: 100;
				transform: rotate(450deg);
			}
		}
	}
</style>
